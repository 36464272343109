import styled from "styled-components";
import { Label } from "./Input";
import { Theme } from "./theme";
import { SubtitlesM } from "./Typography";
import {
  CloudDownloadIcon,
  FolderOpenIcon,
  XIcon,
} from "@heroicons/react/outline";
import { useState, useEffect, useMemo } from "react";
import { downloadBase64File } from "../../hooks/useDownloadPatientForm";
import { FileUploadsModal } from "../../components/molecules/FileUploadsModal";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import { useCreatePatientFormMutation } from "../../redux/reduxQuery/patientFormApi";
import { Spinner } from "@tyrannosaurustech/ui";
import { uniqBy } from "lodash";

interface IFileInput {
  config: {
    label: string;
    required: boolean;
    id: string;
    multiple?: boolean;
    canDelete?: boolean;
    canUpload?: boolean;
    style?: any;
  };
  error?: boolean;
  errorText?: string;
  errors: any;
  setValue: any;
  register: any;
  control: any;
  getValues: any;
  setFormValue?: any;
  onChange?: any;
  value?: any;
  initialValues: [
    {
      name: string;
      id: string;
    }
  ];
  type: "hipaaAuthorization" | "other";
  onFileDelete?: (file: any) => any;
}

const StyledFileInput = styled.label`
  color: ${({ theme }) => theme.colors.primaries_000};
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  text-transform: capitalize;
  cursor: pointer;
  align-items: center;
  display: flex;
  margin-right: 1rem;
  input {
    display: none;
  }
`;

const StyledWrapper = styled.div`
  margin: 0.75rem 0;
  display: flex;
`;

const StyledXIcon = styled(XIcon)`
  margin: 5px;
  width: 1.8rem;
  cursor: pointer;
`;

const StyledDownloadIcon = styled(CloudDownloadIcon)`
  margin: 5px;
  width: 1.6rem;
  cursor: pointer;
`;

const TempFile = ({
  name,
  downloadUrl,
  onDelete,
  existingFile,
  canDelete,
  style,
  loading,
  uppy,
}: {
  name: string;
  downloadUrl?: string | null;
  onDelete: () => any;
  existingFile?: { id: string };
  canDelete?: boolean;
  loading?: boolean;
  style?: any;
  uppy: Uppy;
}) => {
  return (
    <div className="flex flex-row h-10 w-96 p-1 my-1 items-center justify-between rounded-lg border border-neutral-200">
      <span className="text-ellipsis line-clamp-1">{name}</span>
      <div className="flex flex-row items-center">
        {downloadUrl && (
          <StyledDownloadIcon
            onClick={() => window.open(downloadUrl, "_blank")}
            className="cursor-pointer"
          />
        )}
        {loading && (
          <div className="p-2">
            <Spinner colorClassName="" />
          </div>
        )}
        {canDelete && (
          <StyledXIcon
            onClick={() => onDelete()}
            className="cursor-pointer"
            style={style}
          />
        )}
      </div>
    </div>
  );
};

const FileInput = (props: IFileInput) => {
  const [values, setValues] = useState<string[]>([]);
  const [files, setFiles] = useState<string[]>([]);
  const [fileProgress, setFileProgress] = useState<{ [key: string]: number }>(
    {}
  );
  const [fileProgressById, setFileProgressById] = useState<{
    [key: string]: number;
  }>({});
  const [showModal, setShowModal] = useState(false);
  const [initialValues, setInitialValues] = useState<any>(
    uniqBy(props.initialValues, (v) => v.id)
  );
  const isDisabled =
    !props.config.multiple &&
    (!!values.length || (!values.length && !!initialValues?.length));
  const totalFiles = files.length + (initialValues?.length || 0);
  const [createPatientForm] = useCreatePatientFormMutation();

  const [fileIds, setFileIds] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const finishedFileIds = Object.keys(fileProgressById).filter(
      (key) => fileProgressById[key] === 100
    );
    const patientFormIds = Object.keys(fileIds)
      .filter((key) => finishedFileIds.includes(key))
      .map((key) => fileIds[key]);

    if (props.setFormValue) {
      const existingFiles = props.getValues()[props.config.id] || [];
      props.setFormValue(
        patientFormIds.length
          ? uniqBy(
              [...existingFiles, ...patientFormIds],
              (v: { id: string }) => v.id
            )
          : uniqBy(existingFiles, (v: { id: string }) => v.id)
      );
    } else if (props.onChange) {
      props.onChange(patientFormIds);
    }
  }, [fileIds, fileProgressById, props]);

  useEffect(() => {
    if (initialValues) {
      const existingFileName = new Set(initialValues?.map((file) => file.name));
      const duplicateRemoved = files.filter(
        (file) => !existingFileName.has(file)
      );
      if (duplicateRemoved.length !== files.length) {
        setFiles(duplicateRemoved);
      }
    }
  }, [initialValues, setFiles, files]);

  const uppy = useMemo(() => {
    return new Uppy({
      autoProceed: true,
      allowMultipleUploadBatches: true,
      debug: true,
    })
      .use(AwsS3, {
        getUploadParameters: async (file) => {
          setFileProgress({
            ...fileProgress,
            [file.name]: 0,
          });

          setFileProgressById({
            ...fileProgressById,
            [file.id]: 0,
          });

          const { data } = (await createPatientForm({
            name: file.name,
            type: props.type,
            mimeType: file.type,
            fileSize: file.size,
          })) as { data: any };

          setFileIds((fileIds) => ({
            ...fileIds,
            [file.id]: data,
          }));

          return {
            method: "PUT",
            fields: {},
            url: data.uploadUrl,
            headers: {
              "Content-Type": file.type!,
              "Content-Disposition": "inline",
            },
          };
        },
      })
      .on("upload-progress", (file, progress) => {
        const percentage = 100 * (progress.bytesUploaded / progress.bytesTotal);
        setFileProgress((fileProgress) => ({
          ...fileProgress,
          [file!.name]: percentage,
        }));
        setFileProgressById((fileProgrssById) => ({
          ...fileProgrssById,
          [file!.id]: percentage,
        }));
      });
  }, [createPatientForm, props.type, fileProgress, fileProgressById]);

  return (
    <div>
      <Label width={"100%"}>
        <SubtitlesM
          style={{ margin: "0 0 .5rem 0" }}
          color={Theme.colors.neutrals_000}
        >
          {props.config.label}
          {props.config.required && (
            <SubtitlesM
              color={Theme.colors.danger_000}
              style={{ display: "inline" }}
            >
              {" "}
              *
            </SubtitlesM>
          )}
        </SubtitlesM>
      </Label>
      <StyledWrapper className="flex flex-col">
        {props.config.canUpload && (props.config.multiple || totalFiles < 1) && (
          <StyledFileInput
            style={
              isDisabled
                ? { display: "none" }
                : props.error && !files.length
                ? {
                    border: `1px solid ${Theme.colors.danger_000}`,
                    padding: Theme.spacing.spacing_xs,
                    borderRadius: Theme.spacing.spacing_xs,
                  }
                : {}
            }
            className="mb-2"
          >
            <FolderOpenIcon
              style={{ marginRight: "5px" }}
              width={"0.938rem"}
              color={Theme.colors.primaries_000}
            />
            {props.config.multiple ? "Select Files" : "Select File"}
            <input
              name={props.config.id}
              type="file"
              value={values || ""}
              multiple={props.config.multiple}
              disabled={isDisabled}
              onChange={(e) => {
                if (e.target.files?.length) {
                  const newFiles = [...e.target.files];
                  setFiles([...files, ...newFiles.map((file) => file.name)]);

                  uppy.addFiles(
                    newFiles.map((file) => ({
                      name: file.name,
                      mimeType: file.type,
                      data: file,
                    }))
                  );
                }
              }}
            ></input>
          </StyledFileInput>
        )}

        {props.error && !files.length && (
          <SubtitlesM
            color={Theme.colors.danger_000}
            style={{ display: "inline" }}
          >
            {props.errorText}
          </SubtitlesM>
        )}

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {!!initialValues?.length &&
            initialValues.map((file, index) => (
              <TempFile
                name={file.name}
                downloadUrl={file.downloadUrl}
                style={props.config.style}
                existingFile={file}
                onDelete={() => {
                  if ((file.id || file.name) && props.onFileDelete) {
                    props.onFileDelete({
                      ...file,
                      inputId: props.config.id,
                    });
                    setInitialValues(
                      uniqBy(
                        props.getValues(props.config.id) as { id: string }[],
                        (v) => v.id
                      )
                    );
                  }
                }}
                canDelete={props.config.canDelete}
                uppy={uppy}
              />
            ))}

          {!!files.length &&
            files.map((fileName, index) => (
              <TempFile
                name={fileName}
                onDelete={() => {
                  files.splice(index, 1);
                  delete fileProgress[fileName];
                  setFiles([...files]);
                  setFileProgress({ ...fileProgress });
                  props.setFormValue(files);
                  if (files.length === 0) {
                    setValues([]);
                  }
                }}
                style={props.config.style}
                canDelete={props.config.canDelete}
                uppy={uppy}
                loading={fileProgress[fileName] < 100}
              />
            ))}
        </div>
      </StyledWrapper>
      {showModal && (
        <FileUploadsModal
          title="Uploaded Files"
          files={files}
          initialValues={initialValues}
          onCloseModal={() => setShowModal(false)}
          onFileDelete={(idx) => {
            const existingFiles = props.getValues()[props.config.id] || [];
            const existingFileIndex = existingFiles.findIndex((file) => {
              return file.name === files[idx];
            });
            existingFiles.splice(existingFileIndex, 1);
            files.splice(idx, 1);
            setFiles([...files]);
            props.setFormValue(existingFiles);
            if (files.length === 0) {
              setValues([]);
            }
            if (totalFiles - 1 <= 1) {
              setShowModal(false);
            }
          }}
          onInitialFileDelete={(existingFile?: {
            id: string;
            name: string;
          }) => {
            if (
              (existingFile?.id || existingFile?.name) &&
              props.onFileDelete
            ) {
              props.onFileDelete({
                file: existingFile,
                ...existingFile,
                inputId: props.config.id,
              });
              setInitialValues(
                uniqBy(
                  props.getValues(props.config.id) as { id: string }[],
                  (v) => v.id
                )
              );
            }
            if (totalFiles - 1 <= 1) {
              setShowModal(false);
            }
          }}
          onFileDownload={(file) => {
            downloadBase64File(file);
          }}
          canDelete={props.config.canDelete}
        />
      )}
    </div>
  );
};

export default FileInput;
